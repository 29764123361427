body {
  overflow: hidden;
  height: var(--tg-viewport-height);
  background-color: #000;
  color: #fff;
  font-family: "IBM Plex Mono", monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-appearance: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
}
